.editable {
  display: flex;
  padding: 0.5rem 0;
  flex: auto;
  word-break: break-all;
}

.editable:focus{
  border: none;
  outline: none;
}