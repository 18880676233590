html {
  width: 90vw;
  margin: auto;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .sun-editor,
.sun-editor .se-toolbar,
.sun-editor .se-wrapper .se-wrapper-wysiwyg,
.sun-editor .se-resizing-bar {
  background-color: transparent !important;
  border: none !important;

}

.sun-editor .se-btn-module-border {
  border: none !important;
} */