.lienMenu, .lienMenuSombre, .lienMenuActif {
  text-decoration: none !important;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin: 3px;
  padding: 8px;
}
.lienMenu:hover {
  background-color: #EDF2F7;
}
.lienMenuSombre:hover {
  background-color: #272D38;
}
.lienMenuActif {
  color: white !important;
  background-color: teal;
}